import React from 'react';
import Navbar from './Navbar'; // Adjust the import path if necessary
import './AboutUs.css'; // Custom CSS file for additional styles

const AboutUs = () => {
  return (
    <>
      <Navbar id="navbar" />
      <div className="about-us-background">
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card text-center p-4 shadow-lg border-0">
                <div className="card-body">
                  <h1 className="mb-4">
                    <span className="about-us-title">About Us</span> - <span className="get-my">Get My</span> <span className="pandit">Pandit</span>
                  </h1>
                  <p>
                    Welcome to Get My Pandit, your one-stop destination for all Hinduism-related practices and rituals. We are dedicated to preserving the rich traditions and spiritual practices of Hinduism, offering personalized services to help you seamlessly organize poojas, havans, and other religious ceremonies. Whether you're looking for guidance on specific rituals or seeking to connect with experienced pandits in your area, we provide a trusted and convenient platform to fulfill your spiritual needs.
                  </p>
                  <p>
                    At Get My Pandit, we believe in making religious and cultural practices accessible to everyone. With our extensive network of qualified pandits, you can easily book a professional for your specific ritual, ensuring a flawless and spiritually enriching experience. From Griha Pravesh to weddings, Satyanarayan Katha to Shradh, we cater to all your pooja needs with care, devotion, and authenticity.
                  </p>
                  <p>
                    Experience the divine with Get My Pandit, and let us guide you on your spiritual journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
